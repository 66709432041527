import axios from "axios";

export default class Diagnostic {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
    // this.API_LINK = 'http://localhost:8016';
  }
  
  async saveCategories(data) {
    try {
        const response = await axios({
          method: "post",
          url: `${this.API_LINK}/diagnosis/admin/categories`,
          data:{
            "action": "create",
            "data": {
             "name": data 
            }
        }
        });
        return response;
      } catch (error) {
        throw error;
      }
    
  }
  async getCategories() {
    try {
        const response = await axios({
          method: "post",
          url: `${this.API_LINK}/diagnosis/admin/categories`,
          data:{
            "action": "list",
            "filters": {
                
            }
        }
        });
        return response;
      } catch (error) {
        throw error;
      }
    
  }
  async editCategories(data,id) {
    try {
        const response = await axios({
          method: "patch",
          url: `${this.API_LINK}/diagnosis/admin/categories/${id}`,
          data:{
            "name": data
          }
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    
  }

  async getAllSurveys(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/surveys`,
        data: {
          "action": "list",
          "filters": {
              "filterBy": params
          }
      }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getAllDiagnosticTypes() {

  }
  async deleteQuestionData(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/diagnosis/admin/surveys/${id}`,
        data: {
          "confirmation": true,
      }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createDiagnostic(params) {

  }
  
  async createQuestion(params) {

  }

  async createAnswer(params) {

  }

  async inactiveDiagnostic(id) {

  }

  async getDiagnosticById(id) {

  }

  async editDiagnostic(params) {

  }

  async getDiagnostictats(id, queries) {

  }

  async sendNotification(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/notifications`,
        data:{
          "title": params.title,
          "subtitle": params.descripcion,
          "body": {
              "ops": [{
                  "insert": "Tienes una nueva encuesta por contestar"
              }]
          },
          "type": "diagnosis", //
          "controlNumbers": params.users,
          "published": true,
          "action": "diagnosis" // Identifica la acción que debe realizar mobile
          // "payload": "" // TODO Revisar el comportamiento de la propiedad (No es utilizado)
      }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async cloneDiagnostic(id) {

  }

  async createUserExtern(data) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/users`,
        data:{
          "action": "create",
          "data": data
      }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async editSurvey(params,id) {
    try {
      const response = await axios({
        method: "patch",
        url: `${this.API_LINK}/diagnosis/admin/surveys/${id}`,
        data: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createSurvey(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/surveys`,
        data: {
          "action": "create",
          "data":params
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async surveyById(id) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/diagnosis/admin/surveys/${id}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getAllSurveyTypes() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/diagnosis/admin/questions/types`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createQuestion(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/questions`,
        data: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async createAnswer(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/answers`,
        data: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  
  async editSurvey(params,id) {
    try {
      const response = await axios({
        method: "patch",
        url: `${this.API_LINK}/diagnosis/admin/surveys/${id}`,
        data: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getTemplates() {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/templates`,
        data:{
          "action": "list",
          "data": {
              
          }
      }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async getTemplatesById(id) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/diagnosis/admin/templates/${id}`,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createTemplate(id) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/templates`,
        data:{
          "action": "create",
    "data": {
        "surveyId": id
    }
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async cloneSurvey(id) {
    try {
      const response = await axios({
        method: "put",
        url: `${this.API_LINK}/diagnosis/admin/surveys/${id}/clone`,
        data:{}
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteQuestion(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/diagnosis/admin/questions/${id}`,
        data:{}
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateQuestion(id,data,categoryId) {
    try {
      const response = await axios({
        method: "patch",
        url: `${this.API_LINK}/diagnosis/admin/questions/${id}`,
        data:{
          "content":data,
          "categoryId":Number(categoryId)
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getReport(id, queryParams = '') {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/reports`,
        data:{
          action: "report",
          filters: {
            surveyId: id
          }
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async downloadReport(id, format = 'excel') {
    const url = `${this.API_LINK}/diagnosis/admin/reports?format=${format}`
    try {
      const response = await axios({
        method: 'post',
        url,
        data:{
          action: 'report',
          filters: {
            surveyId: id
          }
        },
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

}


