<template>
  <div id="user-search">
    <header>
      <h1>{{ $t("users.search.title") }}</h1>
      <div class="row">
              <div class="col-md-4">
                 <span>{{ $t("users.search.usersSelected") }}  {{selected.length}}
            </span> 
              <i style="font-size:20px" class="fa btn btn-outline-secondary" @click="showData('all')">&#xf00e;</i>
                 <i style="font-size:20px" class="fa btn btn btn-outline-danger" @click="removeCurrenUsers">&#xf057;</i>
            </div>
            <div class="col-md-2">
              <span>{{ $t("users.search.pendingusers") }}  {{carrito.length}}
              <i style="font-size:20px" class="fa btn ">&#xf071;</i>
              </span>
            </div>
                  <div class="col-md-4">
                  <div class="container-input">
                        <input type="file" name="file-2" id="file-2" class="inputfile inputfile-2" @change="loadCSV($event)" accept=".csv" data-multiple-caption="{count} archivos seleccionados" multiple />
                        <label for="file-2" v-if="tabsType == 'audi' ">
                        <i style='font-size:24px' class='fas'>&#xf6dd;</i>
                        <span class="iborrainputfile" id="filecsv"> {{ $t("users.search.selectFile") }}</span>
                        </label>
                        <label @click="openEditModal('j')" v-if="tabsType == 'externos' ">
                          <span class="iborrainputfile" id="filecsv">Crear nuevo usuario externo</span>
                        </label>
                        <i style='font-size:24px' class='fas btn-outline-secondary' @click="showInf()">&#xf05a;</i>
                  </div>
                  </div>
                        <div class="col-md-2">
                           <button class="select-btn" @click="selectUsers"> {{ $t("users.search.save_btn") }}</button>
                        </div> 
      </div>
    </header>
<br/>
  <div class="filters row">
      <div class="col-md-12 mr-5">
        <div class="filters-pills">
          <button
            type="button"
            class="btn btn-filter"
            v-for="(item, index) in filters"
            :key="index">
            {{$t(`users.search.table.headings.${item.label.split('-')[0]}`) + '-'}}:
            {{item.value}} Total :{{item.total}}
            <i style="font-size:15px" class="fa btn btn-outline-secondary" @click="showData(index)">&#xf00e;</i>
            <i style="font-size:15px" class="fa btn btn btn-outline-danger" @click="removeFilter(index)">&#xf057;</i>
          </button>
        </div>
      </div>
    </div>
    <div :class="[tabs == true ? 'tabs' : '']">
      <ul v-if="tabs == true">
        <li :class="['tab', tabsType == 'audi' ? 'active' : 'inactive']" @click="changeLang('audi')">
          Audi
        </li>
        <li :class="['tab', tabsType == 'externos' ? 'active' : 'inactive']" @click="changeLang('externos')">
          Temporales

        </li>
      </ul>
    </div>
    <div :class="[tabs ? 'tab-container' : '']">
<div  class="row" v-if="tabsType == 'audi'">
<div class="col-sm-12" >
    <v-server-table
      :columns="table_options.columns"
      :options="table_options.options"
      ref="userTable"
      fix-header>
      <!-- Select All -->
      <template #filter__carritochk>
        <label for="toggleAll">{{ $t("users.search.table.toggleAll") }}</label>
        <br />
        <input
          type="checkbox"
          name="toggleAll"
          id="toggleAll"
          v-model="allChecked"
          v-on:click="selectAll"
        />
      </template>
        <template #filter__select>
         <button class="select-btn" @click="addUsers">{{ $t("users.search.addUsers") }}</button>
      </template>
      <!-- Checks -->
      <template v-slot:select="props">
        <input
          disabled
          type="checkbox"
          :name="`select${props.row.id}`"
          :id="`select${props.row.id}`"
          :value="props.row.controlNumber"
          v-model="selected"
        />
      </template> 
        <template v-slot:carritochk="props">
        <input
          type="checkbox"
          :name="`select${props.row.id}`"
          :id="`select${props.row.id}`"
          :value="props.row.controlNumber"
          v-model="carrito"
          @click="chk(props.row.controlNumber,$event)"
        />
      </template> 
      <!-- Created -->
      <template #createdAt="props">{{ props.row.createdAt | formatDate(appLang) }}</template>
      <!-- Updated -->
      <template #updatedAt="props">{{ props.row.updatedAt | formatDate(appLang) }}</template>
    </v-server-table>
    </div>
    <b-modal size="sm" centered ref="my-error" hide-footer  scrollable :title="$t('users.search.noValidData')" tabindex="-1" >
    <ul id="example-1">
          <li v-for="(err,index) in error" :key="index">
            {{ err }}
          </li>
        </ul>
    </b-modal>
    <b-modal size="lg" centered ref="my-info" hide-footer  scrollable :title="$t('users.search.infoCsv')" tabindex="-1" >
    <div v-if="appLang =='es'">
        <p>
              Archivo en formato .csv
              <br/>
              Nombre de columna "no_control"
               <br/>
              Formato de celda "Texto"
               <br/>
              Cada registro debe contar con 6 caracteres
            </p>
            </div>
                <div v-if="appLang =='en'">
                File in .csv format
                <br/>
                Column name "no_control"
                  <br/>
                  Format cell "Text"
                    <br/>
                     Each record must have 6 characters
                </div>
    </b-modal>
</div>
<b-modal :modal-class="classmodal" size="xl" centered ref="my-modal" hide-footer  scrollable :title="$t('users.search.listDetail')" tabindex="-1" >
        <div class="row">
                          <div class="col-md-12">
                            <v-client-table v-if="tableData" :data="tableData" :columns="table_optionsCarga.columns" :options="table_optionsCarga.options"/>
                          </div>   
        </div>
    </b-modal>
<div class="row scroll" v-if="tabsType == 'externos'">
  <v-server-table
      :columns="table_optionsExtern.columns"
      :options="table_optionsExtern.options"
      ref="userTable"
      fix-header>
      <!-- Select All -->
      <template #filter__carritochk>
        <label for="toggleAll">{{ $t("users.search.table.toggleAll") }}</label>
        <br />
        <input
          type="checkbox"
          name="toggleAll"
          id="toggleAll"
          v-model="allChecked"
          v-on:click="selectAll"
        />
      </template>
        <template #filter__select>
         <button class="select-btn" @click="addUsers">{{ $t("users.search.addUsers") }}</button>
      </template>
      <!-- Checks -->
      <template v-slot:select="props">
        <input
          disabled
          type="checkbox"
          :name="`select${props.row.id}`"
          :id="`select${props.row.id}`"
          :value="props.row.controlNumber"
          v-model="selected"
        />
      </template> 
        <template v-slot:carritochk="props">
        <input
          type="checkbox"
          :name="`select${props.row.id}`"
          :id="`select${props.row.id}`"
          :value="props.row.controlNumber"
          v-model="carrito"
          @click="chk(props.row.controlNumber,$event)"
        />
      </template> 
      <!-- Created -->
      <template #createdAt="props">{{ props.row.createdAt | formatDate(appLang) }}</template>
      <!-- Updated -->
      <template #updatedAt="props">{{ props.row.updatedAt | formatDate(appLang) }}</template>
    </v-server-table>
      </div>
</div>
<!--MODAL-->
<Modal
       v-if="newModalActive"
       v-on:closeModal="closeModal"
       >
         <!-- Modal title -->
         <template #header>
           <span class="title">Agregar usuario externo</span>
         </template>
         <!-- Modal body -->
         <template>
           <div class="form-container">
            <div v-if="errorData">
            <b-alert
              v-model="errorData"
              variant="danger"
              dismissible
              id="alert-modal"
            >{{ mensajeError }}</b-alert>
            </div>
             <!-- Name -->
             <div class="input-container">
               <label for="name">Nombre</label>
               <input
               v-model="nameEdit"
               type="text"
               id="name"
               name="name"
              >
               <div class="error-msg">
                <span v-if="this.nameEdit.length == 0">Campo requerido</span>
               </div>
             </div>
             <div class="input-container">
               <label for="name">Apellido paterno</label>
               <input
               v-model="lastName"
               type="text"
               id="name"
               name="name"
              >
               <div class="error-msg">
                <span v-if="this.lastName.length == 0">Campo requerido</span>
               </div>
             </div>
             <div class="input-container">
               <label for="name">Apellido materno</label>
               <input
               v-model="mothersLastName"
               type="text"
               id="name"
               name="name"
              >
               <div class="error-msg">
                <span v-if="this.mothersLastName.length == 0">Campo requerido</span>
               </div>
             </div>
             <div class="input-container">
               <label for="name">Telefono</label>
               <input
               v-model="personalCellphoneNumber"
               type="number"
               id="name"
               name="name"
              >
               <div class="error-msg">
                <span v-if="this.personalCellphoneNumber.length == 0">Campo requerido</span>
                <span v-if="this.personalCellphoneNumber.length > 0 && this.personalCellphoneNumber.length < 7">Número mínimo 7 dígitos</span>
               </div>
             </div>
             <div class="input-container">
               <label for="name">Correo</label>
               <input
               v-model="companyEmail"
               type="text"
               id="name"
               name="name"
              >
               <div class="error-msg">
                <span v-if="!validEmail.test(this.companyEmail) && this.companyEmail.length > 0">Formato del correo incorrecto</span>
                <span v-if="this.companyEmail.length == 0">Campo requerido</span>
               </div>
             </div>
            
           </div>
         </template>
         <!-- Modal footer -->
         <template #footer>
           <div class="modal-actions">
             <span
             @click="newModalActive=false"
             class="cancel-btn"
             >Cancelar</span>
             <button
             type="button"
             @click="save()"
             class="btn btn-dark save-btn"
             >Guardar</button>
           </div>
         </template>
       </Modal>
  </div>
</template>

<script>
import moment from "moment";
import API_SERVICE from "@/lib/api/Users.js";
import API_SERVICE_DIAGNOSTIC from "@/lib/api/Diagnostic.js";
const Modal = () => import("@/components/Modal");
import lodash from "lodash";
import { mapState, mapMutations } from "vuex";
let cmsUserFilter;
const api = new API_SERVICE();
const api_diagnostic = new API_SERVICE_DIAGNOSTIC();
import { wildcard } from "@/lib/constants.js";
export default {
  components: {
    Modal

  },
  props: {
    return_to_path: {
      required: false,
    },
    path_id: {
      required: false,
    },
  },
  data: function () {
    return {
      validEmail :  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
      mensajeError:"Es necesario llenar todos los campos!",
      errorData:false,
      nameEdit:"",
      lastName:"",
      mothersLastName:"",
      personalCellphoneNumber:"",
      companyEmail:"",
      newModalActive: false,
      tabs: false,
      tabsType: "audi",
      classmodal: ['classmodal'],

      carga:true,
      detalle:false,
      carrito:[],

      datatable: null,
      users: null,
      selected: [],
      error:[],
     
      allChecked: false,
      usersParams: {},
      dinamycObj: {},
      filters: [],
      arrayDinamycObj: [],

      consulta:[],

      cont:0,
      parse_header:[],
      parse_csv:[],
      sortOrders:{},
      sortKey:'',
       options: {
        },
        columns: [
          "name",
          "lastname",
          "motherslastname",
          "controlnumber",
          "companyemail",
          "directbosscontrolnumber",
          "uo",
          "uoDescription",
          "iduo",
          "funcion",
          "birthdate",
          "gender",
          "admissiondate",
          "workingshift",
          "dailyworkingshift",
          "maritalstatus",
          "numberofchildren",
          "personalcellphonenumber",
          "status",
          "type",
          "typedescription"],
           tableData: null
      
    };
  },
  filters: {
    birthdateFormat: function (value) {
      if (value) return moment(value).format("l");
    },
    capitalize: function(str){
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },

  computed: {
    userTableExtern: function () {
      return this.$refs.userTable;
    },
    appLang: function () {
      return this.$store.getters["settings/currentLang"];
    },
    userTable: function () {
      return this.$refs.userTable;
    },
    table_columns: function () {
      if (this.$route.query.path === "Edit Survey") {
        if(this.tabsType == 'externos'){
          return [
          "carritochk",
          "select",
          "name",
          "lastName",
          "mothersLastName",
          "controlNumber",
          "companyEmail",
          "personalCellphoneNumber",
        ];
        }else{
          return [
          "carritochk",
          "select",
          "name",
          "lastName",
          "mothersLastName",
          "controlNumber",
          "companyEmail",
          "directBossControlNumber",
          "uo",
          "uoDescription",
          "idUo",
          "funcion",
          "birthdate",
          "gender",
          "admissionDate",
          "workingShift",
          "dailyWorkingShift",
          "maritalStatus",
          "numberOfChildren",
          "personalCellphoneNumber",
          "status",
          "type",
          "typeDescription",
        ];
        }
        
      } else {
        if(this.tabsType == 'externos'){
          return [
          "carritochk",
          "select",
          "name",
          "lastName",
          "mothersLastName",
          "controlNumber",
          "companyEmail",
          "personalCellphoneNumber",
        ];
        }else{
        return [
          "carritochk",
          "select",
          "name",
          "lastName",
          "mothersLastName",
          "controlNumber",
          "companyEmail",
          "directBossControlNumber",
          "uo",
          "uoDescription",
          "idUo",
          "funcion",
          "birthdate",
          "gender",
          "admissionDate",
          "workingShift",
          "dailyWorkingShift",
          "maritalStatus",
          "numberOfChildren",
          "personalCellphoneNumber",
          "status",
          "type",
          "typeDescription",
        ];
        }
      }
    },
    table_headings: function () {
      if (this.table_columns) {
        const headings = {};
        this.table_columns.forEach((column) => {
          headings[column] = this.$t(`users.search.table.headings.${column}`);
        });
        return headings;
      }
      return null;
    },
    table_filters: function () {
      return this.table_columns.filter((column) => column !== "select" && column !== "carritochk");
    },
    table_options: function () {
      return {
        columns: this.table_columns,
        options: {
          columnsDropdown: true,
          filterByColumn: true,
          headings: this.table_headings,
          filterable: this.table_filters,
          sortable: [],
          resizableColumns: false,// columnas fijas
          perPage: 25,
          perPageValues: [10, 25, 50, 100],
          columnsClasses: {
            select: "select-column",
            id: "id-column",
          },
          texts: {
            filterBy: "{column}",
            columns: this.$t("users.search.table.column_toggler"),
            limit: this.$t("users.search.table.limit")
          },
          requestAdapter: function (data) {
            const queries = {};
            queries.page = data.page - 1;
            queries.limit = data.limit;
            queries.query = data.query;
           
            queries.cmsUserFilter = cmsUserFilter ? 'collaborator' : cmsUserFilter;
         
            return queries;
          },
          requestFunction: async function (data) {
            this.$store.commit("global/SET_LOADING", true);
            try {
              const resp =  await api.getUsers(data);
              this.$emit("tipo");
              this.$store.commit("global/SET_LOADING", false);
              return resp
            } catch (error) {
              this.$store.commit("global/SET_LOADING", false);
              console.log(error);
              throw error;
            }
          },
          responseAdapter: function (response) {
            return {
              data: response.data,
              count: response.total,
            };
          },
        },
      };
    },
    table_optionsExtern: function () {
      return {
        columns: this.table_columns,
        options: {
          columnsDropdown: true,
          filterByColumn: true,
          headings: this.table_headings,
          filterable: this.table_filters,
          sortable: [],
          resizableColumns: false,// columnas fijas
          perPage: 25,
          perPageValues: [10, 25, 50, 100],
          columnsClasses: {
            select: "select-column",
            id: "id-column",
          },
          texts: {
            filterBy: "{column}",
            columns: this.$t("users.search.table.column_toggler"),
            limit: this.$t("users.search.table.limit")
          },
          requestAdapter: function (data) {
            const queries = {};
            queries.page = data.page - 1;
            queries.limit = data.limit;
            queries.query = data.query;
           
            queries.cmsUserFilter = cmsUserFilter ? 'collaborator' : cmsUserFilter;
         
            return queries;
          },
          requestFunction: async function (data) {
            this.$store.commit("global/SET_LOADING", true);
            try {
              const dataFilter={...data.query,"userType":"user_extern"}
              console.log(dataFilter);
              const pageresponse=data.page*data.limit;
              
              const resp =  await api.getUsersExtern(dataFilter,pageresponse,data.limit);
              this.$emit("tipo");
              this.$store.commit("global/SET_LOADING", false);
              return resp;
            } catch (error) {
              this.$store.commit("global/SET_LOADING", false);
              console.log(error);
              throw error;
            }
          },
          responseAdapter: function (response) {
            return {
              data: response.items,
              count: response.total,
            };
          },
        },
      };
    },
     table_headingsCarga: function () {
      if (this.columns) {
        const headings = {};
        this.columns.forEach((column) => {
          headings[column] = this.$t(`users.search.tableCarga.headings.${column}`);
        });
        return headings;
      }
      return null;
    },
    table_filtersCarga: function () {
      return this.columns.filter((column) => column !== "select");
    },

 table_optionsCarga: function () {
      return {
        columns: this.columns,
        options: {
          columnsDropdown: true,
          filterByColumn: true,
          headings: this.table_headingsCarga,
          filterable: this.table_filtersCarga,
          sortable: [],
          resizableColumns: false,// columnas fijas
          perPage: 25,
          perPageValues: [10, 25, 50, 100],
          columnsClasses: {
            select: "select-column",
            id: "id-column",
          },
          texts: {
            filterBy: "{column}",
            columns: this.$t("users.search.table.column_toggler"),
            limit: this.$t("users.search.table.limit")
          },
        },
      };
    },
    recipients: function () {
      return this.$store.getters["users/recipients"];
    },
  },

  beforeMount: async function () {
    this.tabs = this.$route.query.path == "Edit Diagnostic" ? true : false;
    cmsUserFilter = this.$route.params.cmsUserFilter;
    if (this.$route.params.controlNumbers) {
      this.selected = this.$route.params.controlNumbers === wildcard ? [] : this.$route.params.controlNumbers;
    } else {
      this.selected = this.recipients || [];
    }
  },
  mounted() {
    this.$on("vue-tables.userTable.filter", function (data) {});
  },
  methods: {
     save: async function(){
      var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
      const sendData={
        name:this.nameEdit,
        lastName:this.lastName,
        mothersLastName:this.mothersLastName,
        personalCellphoneNumber:this.personalCellphoneNumber,
        companyEmail:this.companyEmail
      }
      if(this.nameEdit=="" || this.lastName=="" || this.mothersLastName=="" || this.personalCellphoneNumber=="" || this.companyEmail==""){
        this.mensajeError="Es necesario llenar todos los campos!",
        this.errorData=true;
      }else if(!validEmail.test(this.companyEmail)){
        this.mensajeError="Formato del correo incorrecto",
        this.errorData=true;
      }else{
        
        this.$store.commit("global/SET_LOADING", true);
      try{
        const resp = await api_diagnostic.createUserExtern(sendData);
        console.log(resp);
        
        this.errorData=false;
        this.newModalActive=false;
        this.$store.commit("global/SET_LOADING", false);
        //this.tabsType= "audi";
        this.userTableExtern.refresh();
        this.nameEdit="";
        this.lastName="";
        this.mothersLastName="";
        this.personalCellphoneNumber="";
        this.companyEmail="";
    }catch(error){
      console.log("*****",error.response.data.error.message);
      this.$store.commit("global/SET_ALERT", {
          msg: error.response.data.error.message == "DIAGNOSIS_EMAIL_NOT_AVAILABLED" ? "El correo ya existe" : error.response.data.error.message || "Error al guardar la información",
          show: true,
          type: "error"
        });
      this.errorData=false;
        this.newModalActive=false;
        this.$store.commit("global/SET_LOADING", false);
        this.tabsType= "audi";
        this.nameEdit="";
        this.lastName="";
        this.mothersLastName="";
        this.personalCellphoneNumber="";
        this.companyEmail="";
      
    }
      }
      
      
    },
    openEditModal(category) {
        
        this.newModalActive = true;
    },
    closeModal() {
       
        this.newModalActive = false;
    },
    changeLang(lang) {
      console.log(lang);

      this.tabsType = lang;
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    Cerrar(){
      alert("Cerrar");
      this.carga = true;
      this.detalle = false;
    },
  filterStatus: function()
    {
      this.userTable.setFilter({"status":""});
    },

  
     csvJSON(csv){
       var vm = this
       var lines = csv.split("\r\n")
       var result =[]
       var result2 =[]
       var headers = lines[0].split(",")  
       vm.parse_header = lines[0].split(",")
       lines[0].split(",").forEach(function(key){
         vm.sortOrders[key] = 1
       })
       lines.map(function(line,indexline){
         if(indexline <1) return //Jump header line
         var obj = {}
         var currentline = line.split(",")
           headers.map(function(header,indexHeader){
           obj[header] = currentline[indexHeader]
           var dato =  currentline[indexHeader]
            if(dato.length<6){
              result.push(dato);
             
            }
            else if(dato.length>7){
              result.push(dato)}
            else if(isNaN(dato)){
              result.push(dato);
            }
            else{
            result2.push(currentline[indexHeader]);
            }
         })
       })
       this.error = result; 
       if(this.error.length>0){
         this.$refs['my-error'].show();
       }
          this.filters.push({
          data: result2,
          label: "Carga",
          value: "CSV",
          total: result2.length});
           this.selected = this.selected.concat(result2);
                this.$store.commit("global/SET_ALERT", {
                msg: this.$t("users.search.Succes")+" "+ result2.length,
                show: true,
                type: "success"
               });
       return result; 

     },
    
     getarray: async function()
     {
       const resp = await api.getFromArray({"controlNumbers":this.selected});
       this.tableData = resp.data;
     },
     loadCSV(e){
        var vm = this
        if(window.FileReader){
          var reader = new FileReader();
          reader.readAsText(e.target.files[0]);
          document.getElementById('filecsv').textContent = e.target.files[0].name;
          reader.onload = function(event){
            var csv = event.target.result;
            vm.parse_csv = vm.csvJSON(csv)
          };
          reader.onerror = function(evt){
            if(evt.target.error.name=='NotReadableError'){
                 this.$store.commit("global/SET_ALERT", {
                 msg: "Cannot read File !",
                 show: true,
                type: "error"
               });
            }
          };
        }
        else{
              this.$store.commit("global/SET_ALERT", {
                 msg: "FileReader are not supported in this browser",
                 show: true,
                type: "error"
               });
        }
    },

    cleanRepeatedControlsNumbers(selected = []) {
      return this.lodash.uniqBy(selected, function (e) {
        return e;
      });
    },
    async chk(Number,cb)
    {
    if(cb.target.checked)
    {
      this.carrito.push(Number);
    }
    else{
       this.carrito = this.carrito.filter((e)=> e !== Number )
    }
    },
    removeCurrenUsers() {
      this.selected = [];
      this.filters = [];
      this.dinamycObj =[];
      this.allChecked = false;
    },
    async addUsers() {
     if(this.carrito.length==0){
        this.$store.commit("global/SET_ALERT", {
                 msg: this.$t("users.search.noUser"),
                 show: true,
                type: "error"
               });
} 
else{
      this.$store.commit("global/SET_LOADING", true);
      let filters = this.lodash.cloneDeep(this.$refs.userTable._data.query);
      let keyAux = "";
      let values = "";
      for (const key in filters)
      {
      if
        (filters[key] === "")
                                {

                                }
                                                        else
                                                              {
                                                                keyAux += key + "-";
                                                                values += filters[key] + "-";
                                                              }
      }
      let controlNumbers = this.selected;
      if (this.dinamycObj.hasOwnProperty(keyAux)) {
            this.dinamycObj[keyAux] =
            this.dinamycObj[keyAux].concat(controlNumbers);
      } else {
        this.dinamycObj[keyAux] = controlNumbers;
      }
      if (keyAux === "") {
          this.filters.push({
          data: this.carrito,
          label: "all"+ "-" + this.cont++,
          value: "Lista-"+ this.cont++,
          total: this.carrito.length
        });
      } else {
        this.filters.push({
          data: this.carrito,
          label: keyAux,
          value: values,
          total: this.carrito.length
        });
      }
      this.selected = this.selected.concat(this.carrito);
      this.selected = this.cleanRepeatedControlsNumbers(this.selected);
       this.$store.commit("global/SET_ALERT", {
                 msg: this.$t("users.search.userAdd") + this.carrito.length ,
                 show: true,
                type: "success"
               });
       this.carrito=[];
       this.allChecked = false;
       this.$store.commit("global/SET_LOADING", false);
       }

    },
    async listenTo(a) {
      let flag = false;
      if (a.value === "") {
        flag = true;
      }
      if (!flag) {
        try {
          if (!this.allChecked) {
            let clone = this.lodash.cloneDeep(this.$refs.userTable._data.query);
            for (const key in clone) {
              if (clone[key] === "") {
                delete clone[key];
              }
            }

            let data = {
              page: this.$refs.userTable._data.page - 1,
              limit: this.$refs.userTable._data.limit,
              query: clone,
              all: true,
            };
            let appendArray = [];
            const resp = await api.getUsers(data);

            appendArray = resp.data.map((key) => key.controlNumber);
            this.selected = this.selected.concat(appendArray);
            this.selected = this.cleanRepeatedControlsNumbers(this.selected);
          } else {
            this.selected = [];
          }
        } catch (error) {
          throw error;
        }
      }
    },

    selectAll: async function () {
      try {
         this.$store.commit("global/SET_LOADING", true);
        if (!this.allChecked) {
          let clone = this.lodash.cloneDeep(this.$refs.userTable._data.query);
          //Poner filtros
          for (const key in clone) {
            if (clone[key] === "") {
              delete clone[key];
            }
          }

          let data = {
            page: this.$refs.userTable._data.page - 1,
            limit: this.$refs.userTable._data.limit,
            query: clone,
            all: true,
          };
          let appendArray = [];
          const resp = await api.getUsers(data);
          appendArray = resp.data.map((key) => key.controlNumber);
          this.carrito = this.carrito.concat(appendArray);
          this.carrito = this.cleanRepeatedControlsNumbers(this.carrito)  
        } else {
          this.carrito = [];
        }
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        throw error;
      }
    },
    removeFilter: function (index) {
      let key = this.filters[index].label;

      if(key == "Carga"){
        document.getElementById("file-2").value = "";
        document.getElementById('filecsv').textContent = "Selecciona Archivo";
      }
      delete this.dinamycObj[key];
      let arrayToRemove = this.filters[index].data;
      this.filters.splice(index, 1);
      this.selected = this.selected.filter( (el) =>
                                                      !arrayToRemove.includes(el));
    },

    showInf: function(){
      this.$refs['my-info'].show();
    },
      showData: async function (index) {
             
            try {
               if(index =="all"){
                if(this.selected.length==0)
                {
                  this.$store.commit("global/SET_ALERT", {
                  msg: this.$t("users.search.noRegister"),
                  show: true,
                  type: "error"
                });
                }
                else{
                this.showModal();
                this.$store.commit("global/SET_LOADING", true);
                const resp = await api.getFromArray({"controlNumbers":this.selected});
                this.tableData = resp.data;
                this.$store.commit("global/SET_LOADING", false);
                }
                
               }
              else{
                this.showModal();
                this.$store.commit("global/SET_LOADING", true);
                const resp = await api.getFromArray({"controlNumbers":this.filters[index].data});
                this.tableData = resp.data;
                this.$store.commit("global/SET_LOADING", false);
                }

            } catch (error) {
              this.$store.commit("global/SET_LOADING", false);
                  this.$store.commit("global/SET_ALERT", {
                  msg: error,
                  show: true,
                  type: "error"
                });
            }
    },
    selectUsers: function () {
      if(this.carrito.length>0)
      {
           this.$store.commit("global/SET_ALERT", {
                  msg: this.$t("users.search.userPending") +" :"+this.carrito.length,
                  show: true,
                  type: "error"
                });
        return;
      }
      let users = null;
      if (this.selected.length === this.userTable.data.count) users = null;
      else if (this.selected.length === 0) users = null;
      else users = this.selected;
      this.$store.commit("users/SET_USERS", users);
      if(this.tabs){
        this.$router.push({
          name: "Edit Diagnostic",
          params: {
            questionsData: this.$route.params.questions,
            surveyTime: this.$route.params.surveyTime,
            surveyDescription:this.$route.params.surveyDescription,
            id: this.$route.params.id,
            controlNumbers: users,
            typeAccion:this.$route.params.typeAccion,
            typeTemplate:this.$route.params.typeTemplate,
            nameDesing:this.$route.params.nameDesing,
            date1: this.$route.params.date1,
            time1: this.$route.params.time1,
            date2: this.$route.params.date2,
            time2: this.$route.params.time2,
          },
        });
      }else if (this.$route.params.surveyObj) {
        this.$router.push({
          name: "Edit Survey",
          params: {
            id: this.$route.params.id,
            surveyType: this.$route.params.surveyType,
            controlNumbers: users,
            surveyObj: this.$route.params.surveyObj,
            survey: this.$route.params.survey,
          },
        });
      } else {
        this.$router.push({
          name: this.return_to_path,
          params: {
            id: this.path_id,
            selected: true,
            selectValue: this.$route.params.selectValue,
            name: this.$route.params.name,
            type: this.$route.params.type,
            priority: this.$route.params.priority,
            controlNumbers: this.selected,
            title: this.$route.params.title,
            subtitle: this.$route.params.subtitle,
            cover: this.$route.params.cover,
            body: this.$route.params.body,
            media: this.$route.params.media
          },
        });
      }
    },
  },
};

</script>


<style lang="scss" >
.modal-actions {
     display: flex;
     align-items: center;
     justify-content: flex-end;
   
     > * {
       margin-left: 1rem;
       cursor: pointer;
     }
   
     .cancel-btn {
       &:hover {
         text-decoration: underline;
       }
     }
     .save-btn {
       margin:0 0 0 10px;
       color: white;
       background-color: black;
       border-color: black;
       padding: 10px 20px;
       border-radius: 0px;
       transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
     }
   }
.title {
       font-weight:bold;
     font-size: 1.2rem;
   }
.tab-container{
  border: 1px solid $wg_1;
  padding: 1rem;
}

.tabs {
  width: 100%;
  margin: 2rem 0 0 0;
}

.tabs ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
  position: relative;
  z-index: 9;
}

.tabs .tab {
  @extend %audi-ext-b;
  display: inline-block;
  padding: 0.5rem 2rem;
  border: 1px solid $wg_1;
  background-color: $w_1;
  cursor: pointer;

  &.active {
    background-color: $white;
    border-bottom-color: $white;
    border-bottom: 0px solid white;
  }

  &.inactive {
    color: rgb(116, 114, 114);
  }
}

/**********File Inputs**********/
.container-input {
    text-align: center;
    background: #fff; //#b6b0b0;
    top: 30px;
    border-top: 5px solid #fff; //#000000;
    padding: 5px 0;
    border-radius: 6px;
    width: 70%;
    height: 70%;
    margin: 0 auto;
    margin-bottom: 1em;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
   
}

.inputfile + label {
    max-width: 100%;
    font-size: 1.35rem;
    font-weight: 200;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
}

.inputfile + label i {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.05em;
}

.iborrainputfile {
  font-size:14px; 
}

.inputfile-2 + label {
    color: #fff;
    background-color: #000000;
}

.inputfile-2:focus + label,
.inputfile-2.has-focus + label,
.inputfile-2 + label:hover {
    background-color: #ffffff;
     color: #000000;
      border: 1px solid #000000;
}


#user-search {
  background-color: $white;
  padding: 1rem 1rem;

}
.select-btn {
  align-self: center;
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  border-radius: 0;
  padding: 0.5rem 2rem;
  margin: 1rem 0;
}

.fa-search-plus-circle {
  color: $warn_green;
  font-size: 1.2rem;
  margin: 0.5rem;
}
.fa-times-circle {
  color: $warn_red;
  font-size: 1.2rem;
  margin: 0.5rem;
}
.aui-headline-3{
  font-family: 'audi-extended';
  }
  .classmodal > div{
    position:absolute !important;
    top: 2rem !important;
    left: 15rem !important;
  }

.VueTables__table .actions-column {
    position: sticky;
    right: 0;
    text-align: center;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #f2f2f2;
      top: 0;
      right: 0;
      border-collapse: collapse;
    }
  }
  .VueTables__table .actions-column > * {
    position: relative;
    z-index: 1;
  }
  .table-striped tbody tr:nth-of-type(odd) > .actions-column {
    &:after {
      background-color: #e6e6e6;
    }
  }
  .VueTables__table .id-column {
    max-width: 100px;
  }
  [class$="filter-wrapper"] {
    min-width: 80px;
    max-width: fit-content;
  }
 
 

 

.btn-filter {
    background: #fff !important;
    color:#000000 !important;
}
</style>

<style>
  .VueTables thead  { 
            position: sticky;
            top: 0;
            z-index: 10;
            background-color: #fff;
        }
        .table-responsive { 
            height:600px;
            overflow:scroll;
        }
.VueTables .dropdown {
  margin: 1rem 0;
}
.VueTables__columns-dropdown {
  float: left !important;
}
.VueTables__columns-dropdown > button {
  color: #000 !important;
  background-color: transparent !important;
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
}
.VueTables .dropdown .dropdown-menu {
  padding: 1rem;
}
.VueTables .dropdown .dropdown-menu a {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.VueTables .dropdown .dropdown-menu a > input {
  margin-right: 0.5rem;
}
.scroll{
  overflow: scroll;
}
.error-msg {
  margin-top: 1px;
  margin-bottom: 2px;
  color: red;
  font-size: 14px;
  p {
    margin: 0;
  }
}
.error-msg.address-error {
  width: 100%;
}
</style>
